import { ReactComponent as Options } from "../../assets/svg/icons/options.svg";
import { ReactComponent as Open } from "../../assets/svg/icons/file_open.svg";
import { ReactComponent as UserAvatar } from "../../assets/svg/icons/user-avatar.svg";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { alertError, getDateTime } from "../../utils";
import UserInsight, { getServiceColor } from "../../pages/user/UserInsignt";
import { getUser } from "../../services/UserService";

export const generateRandomTextColor = (type) => {
  const colorOptions = [
    '[#045CF4]',
    '[#AD3A43]',
    '[#538989]',
    '[#E28327]',
    '[#6365D6]',
    'black',
  ]
  const color = colorOptions[Math.floor(Math.random() * 6 )];
  return `${type}-${color}`;
}

const UserRow = ({
  user,
  header,
  selectedOption,
  setSelectedOption,
}) => {

  const navigate = useNavigate();
  const [dateTime, setDateTime] = useState(getDateTime(user?.createdAt));
  const [userServices, setUserServices] = useState([]);
  const [userFirstService, setUserFirstService] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal =() => {
    setModalIsOpen(true);
  }
  const closeModal = () => {
    setModalIsOpen(false);
  }

  // Function to get user services
  /* const fetchUserServices = async (user) => {
    const userId = user?.id;
    try {
      const response = await getUser(userId);
      const services = response?.data?.data?.rows;
      if (services?.length > 0) {
        setUserFirstService(services[0].projectType);
        user.services = services;
        setUserServices(services);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 401) {
        alertError('Session Expired. Please Login');
        navigate('/login');
      }
    }
  } */

  useEffect(() => {
    //user?.id && fetchUserServices(user);
    if (user?.services) {
      const sortServices = user?.services?.rows?.filter((service1, service2) => {
        //console.log(service1, service2)
        if (service1 && service2) {
          return service1?.createdAt - service2?.createdAt;
        } else {
          return service1
        }
      });
      setUserFirstService(sortServices.length > 0 ? sortServices[0]?.projectType : 'Not Connected');
      setUserServices(user.services);
    } else {
      setUserFirstService('Not Connected');
    }
    setDateTime(getDateTime(user?.createdAt));
  },[user])

  const handleOptionClick = () => {
    if (selectedOption !== user?.id) {
      setSelectedOption(user?.id)
    } else {
      setSelectedOption(null);
    }
  }

  return (
    <div
      className={`
        ${header ? 'bg-[#F1F6FF] mt-5 cursor-default' : 'bg-white border-b border-[#EBEBEB] cursor-pointer'}
        h-[60px] w-full flex items-center text-[14px] px-3 md:px-8`
      }
    >
      <div
        onClick={() => !header && openModal()}
        className="w-full h-full flex items-center"
      >
        <div className="w-[80%] md:w-[35%]">
          <div className="w-full h-full flex items-center gap-3">
            <div>
              {
                user?.profilePic
                ?
                <img src={user.profilePic} alt="" width={45} />
                :
                (
                  !header && <UserAvatar className="scale-[0.6]" />
                )
              }
            </div>
            <p className="max-w-[200px truncate">{ header ? 'User' : `${user.firstName} ${user.lastName}`}</p>
          </div>
        </div>
        <div className="hidden md:flex w-[17%]">{ header ? 'User ID' : user.id }</div>
        <div className="hidden md:flex w-[18%]">{ header ? 'Date' : <p className="max-w-[90%] overflow-ellipsis truncate">{dateTime.date}</p> }</div>
        <div className="hidden md:flex w-[15%]">{ header ? 'Time' : dateTime.time }</div>
        <div className="hidden md:flex md:w-[20%]">
          {
            header
            ?
            'Service'
            :
            <div className={`h-[40px] w-[150px] flex items-center justify-center rounded-[30px] ${getServiceColor(userFirstService)}`}>
              {userFirstService}
            </div>
          }
        </div>
      </div>
      <div className="w-5 md:w-10 relative z-[999]">
        <button
          className={`${header ? 'hidden' : 'w-10 flex md:justify-end cursor-default'}`}
          onClick={handleOptionClick}
          onBlur={() => setSelectedOption(null)}
        >
          <Options />
          {
            selectedOption === user?.id &&
            <div className="absolute right-10 top-0 w-[200px] text-[13px] font-[200] text-[#717171] flex flex-col bg-white p-5 pb-0 rounded-[8px] shadow-md">
              <div
                onClick={() => navigate(`/user/${user.id}`)}
                className="flex items-center gap-2 py-4 pl-5 border-b-[0.5px] border-b-[#D8D8D8] cursor-pointer"
              >
                <Open className="scale-90" />
                <p>Open</p>
              </div>
            </div>
          }
        </button>
      </div>

      {
        modalIsOpen &&
        <UserInsight
          openModal={openModal}
          closeModal={closeModal}
          modalIsOpen={modalIsOpen}
          user={user}
          firstService={userFirstService}
          services={userServices}
        />
      }
    </div>
  )
}

export default UserRow;
