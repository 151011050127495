import axios from "../axios";

/* export const getProjects = (page = 1) => {
  return axios.get(`projects/?page=${page}`);
}; */
/* export const createProject = (payload, ordId) => {
  return axios.post("projects/", payload);
}; */

export const getNetworks = () => {
  return axios.get("projects/networks/", {});
};

export const getProjects = () => {
  return axios.get(`dashboard/apiServices`);
};

/* export const getAnalytics = (ordId) => {
  return axios.get("dashboard/analytics");
};

export const getNetwork = (ordId, networkId) => {
  return axios.get(`projects/networks/${networkId}`);
};

export const getProjects = () => {
  return axios.get(`projects/apiservices`);
};

export const getProject = (projectId) => {
  return axios.get(`projects/apiservices/${projectId}`);
};

export const createProject = (payload) => {
  return axios.post("projects/apiservices/", payload);
};

export const deleteProject = (projectId) => {
  return axios.delete(`projects/apiservices/${projectId}`);
};

export const changeApiKey = (projectId, payload) => {
  return axios.patch(`projects/apiservices/${projectId}`, payload);
};
 */