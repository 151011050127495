import User1 from "../../assets/img/u1.png";
import User2 from "../../assets/img/u2.png";
import User3 from "../../assets/img/u3.png";
import User4 from "../../assets/img/u4.png";
import User5 from "../../assets/img/u5.png";

const date1 = new Date().setDate(12);
const date2 = new Date().setDate(13);
const date3 = new Date().setDate(14);
const date4 = new Date().setDate(16);
const date5 = new Date().setDate(18);
const date6 = new Date().setDate(19);


export const response = {
  data: [
    {
      firstName: 'John',
      lastName: 'Gabriel',
      id: '0010',
      createdAt: date2,
      service: 'Validator Node',
      profilePic: User1,
      active: true,
    },
    {
      firstName: 'Steph',
      lastName: 'Amari',
      id: '0020',
      createdAt: date3,
      service: 'Snapshots',
      profilePic: User2,
      active: true,
    },
    {
      firstName: 'Maartin',
      lastName: 'Odegaard',
      id: '0030',
      createdAt: date6,
      service: 'Dedicated Node',
      profilePic: User3,
    },
    {
      firstName: 'Beth',
      lastName: 'Mead',
      id: '0040',
      createdAt: date1,
      service: 'Api Service',
      profilePic: User4,
      active: false,
    },
    {
      firstName: 'Thomas',
      lastName: 'Partey',
      id: '0050',
      createdAt: date4,
      service: 'Validator Node',
      profilePic: User5,
      active: true,
    },
    {
      firstName: 'Bukayo',
      lastName: 'Saka',
      id: '0060',
      createdAt: date5,
      service: 'Dedicated Node',
      profilePic: null,
      active: false,
    },
    {
      firstName: 'Martinelli',
      lastName: 'Gabriel',
      id: '0070',
      createdAt: new Date().getTime(),
      service: 'Snapshots',
      profilePic: null,
      active: true,
    },
  ]
}

