import React, { useEffect, useState } from "react";
import "./App.css";
import { Toaster } from "react-hot-toast";
import { Route, Routes } from "react-router-dom";
import { AuthProvider, useAuth } from "./context/authContext";
import { HeaderProvider } from "./context/headerContext";
import { Dashboard } from "./components/layouts/Dashboard";
import { Auth } from "./components/layouts/Auth";
import { ProjectProvider } from "./context/projectContext";
import { ManualProvider } from "./context/manualContext";
import NotFound from "./pages/others/notFound";
import Users from "./pages/user/Users";
import AppLogin from "./pages/auth/AppLogin";
import UserInsight from "./pages/user/UserInsignt";
import UserLayout from "./components/layouts/UserLayout";
import ApiServices from "./pages/api-services/ApiServices";
import { NetworkProvider } from "./context/networkContext";

const AppWrapper = () => {
  const auth = useAuth();
  const [fetchingUser, setFetchingUser] = useState(true);

  useEffect(() => {
    try {
      const userObj = JSON.parse(localStorage.getItem("user"));
      if (userObj) {
        let expiryDate = new Date(userObj.auth.expiryDate);
        let todaysDate = new Date();
        if (todaysDate > expiryDate) {
          auth.logout();
        } else {
          auth.initUser(userObj);
        }
      }
      setFetchingUser(false);
    } catch (err) {}
  }, []);

  return (
    <div className="">
      {fetchingUser ? (
        <h2>Fetching user</h2>
      ) : (
        <Routes>
          {/* <Route
            path="/login"
            element={
              <Auth path="login">
                <div></div>
              </Auth>
            }
          ></Route>
          <Route
            path="/signup"
            element={
              <Auth path="signup">
                <div></div>
              </Auth>
            }
          ></Route>
          <Route
            path="/reset-password"
            element={
              <ResetAuth>
                <div></div>
              </ResetAuth>
            }
          ></Route>
          <Route
            path="/forgot-password"
            element={
              <ResetAuth>
                <div></div>
              </ResetAuth>
            }
          ></Route>
          <Route
            path="/verify-account"
            element={
              <Auth>
                <div></div>
              </Auth>
            }
          ></Route> */}

          <Route
            path="/"
            element={
              <Auth>
                <AppLogin />
              </Auth>
            }
          ></Route>
          <Route
            path="/login"
            element={
              <Auth>
                <AppLogin />
              </Auth>
            }
          ></Route>
          <Route
            path="/users"
            element={
              <Dashboard>
                <Users />
              </Dashboard>
            }
          ></Route>
          {/* <Route
            path="/users/:id"
            element={
              <Dashboard>
                <UserLayout >
                  <UserInsight />
                </UserLayout>
              </Dashboard>
            }
          ></Route> */}
          <Route
            path="/api-services"
            element={
              <ManualProvider>
                <Dashboard>
                  <ApiServices />
                </Dashboard>
              </ManualProvider>
            }
          ></Route>
          <Route
            path="/settings"
            element={
              <Dashboard>
                <div></div>
              </Dashboard>
            }
          ></Route>
          <Route
            path="*"
            element={
                  <NotFound />
            }
          ></Route>
        </Routes>
      )}
    </div>
  );
};
function App() {
  return (
    <HeaderProvider>
    <AuthProvider>
      <ProjectProvider>
        <ManualProvider>
          <NetworkProvider>
            <AppWrapper />
            <Toaster position="top-right" />
          </NetworkProvider>
        </ManualProvider>
      </ProjectProvider>
    </AuthProvider>
    </HeaderProvider>
  );
}

export default App;

