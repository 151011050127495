import {useState,useContext, createContext, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { getNetworks } from "../services/ProjectsService";
import { alertError, NETWORK_STATE } from "../utils";
import { useAuth } from "./authContext";

const NetworkContext = createContext()


export const NetworkProvider = ({children}) => {
    const [networks, setNetworks] = useState(null);
    const [status, setStatus] = useState(NETWORK_STATE.IDLE);
    const auth = useAuth();
    const navigate = useNavigate();


    const fetchNetworks = async () => {
      try {
        setStatus(NETWORK_STATE.LOADING);
        const cachedNetworks = localStorage.getItem("networks");
        if (cachedNetworks) {
          setNetworks(JSON.parse(cachedNetworks));
          setStatus(NETWORK_STATE.SUCCESS);
        } else {
          const response = await getNetworks();
          setNetworks(response.data.data);
          localStorage.setItem("networks", JSON.stringify(response.data.data));
          setStatus(NETWORK_STATE.SUCCESS);
        }
      } catch (err) {
        if (err.response.status === 401) {
          alertError('Session Expired! Please Login.');
          setStatus(NETWORK_STATE.ERROR);
          navigate('/login');
        }
        setStatus(NETWORK_STATE.ERROR);
      }
    };

    useEffect(() => {
      auth.user && fetchNetworks();
    }, [auth.user]);

    return(
        <NetworkContext.Provider
        value={{
            networks,
        }}
        >
            {children}
        </NetworkContext.Provider>
    )


}

export const useNetworks = () => useContext(NetworkContext);

