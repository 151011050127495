import { NavLink } from "react-router-dom";
import Menu from "../../assets/img/menu.png";
import { ReactComponent as Dashboard } from "../../assets/svg/nav/dashboard_fill.svg";
import { ReactComponent as Settings } from "../../assets/svg/nav/settings_fill.svg";
import { ReactComponent as Soon } from "../../assets/svg/nav/soon.svg";
import { ReactComponent as Documentation } from "../../assets/svg/nav/docs.svg";
import { ReactComponent as Service } from "../../assets/svg/nav/service_fill.svg";
import { ReactComponent as Support } from "../../assets/svg/nav/help_fill.svg";
import { ReactComponent as Logout } from "../../assets/svg/nav/logout.svg";
import { useAuth } from "../../context/authContext";

export default function Mininav({ resizeNav }) {
  const { logout } = useAuth();

  return (
    <nav className={`min-nav`}>
      <div className="min-nav__header">
        <button
          onClick={() => {
            resizeNav();
          }}
        >
          <img src={Menu} alt="" width={27} />
        </button>
      </div>

      <div className="flex flex-col items-end h-[60px] text-white opacity-50 text-sm pl-10 uppercase"></div>

      <div className="min-nav__cont">
        <div className="min-nav__cont-top">
          <div className="h-[50px] flex items-center justify-center group">
            <NavLink className="min_link " to="/users" onClick={() => {}}>
              <Dashboard />
            </NavLink>
            <span className="group-hover:flex">User</span>
          </div>

          <div className="h-[50px] flex items-center justify-center group">
            <NavLink className="min_link" to="/api-services" onClick={() => {}}>
              <Service />
            </NavLink>
            <span className="group-hover:flex">API Services</span>
          </div>

          <div className="h-[50px] flex items-center justify-center group">
            <div className="group min_link cursor-not-allowed relative">
              <Soon />
            </div>
            <span className="group-hover:flex">Dedicated Nodes</span>
          </div>

          <div className="h-[50px] flex items-center justify-center group">
            <div className="group min_link cursor-not-allowed relative">
              <Soon />
            </div>
            <span className="group-hover:flex">Validator Nodes</span>
          </div>

          <div className="h-[50px] flex items-center justify-center group">
            <div className="group min_link cursor-not-allowed relative">
              <Soon />
            </div>
            <span className="group-hover:flex">Data Services</span>
          </div>

          <div className="h-[50px] flex items-center justify-center group">
            <NavLink className="min_link" to="/settings" onClick={() => {}}>
              <Settings />
            </NavLink>
            <span className="group-hover:flex">settings</span>
          </div>
        </div>

        <div className="min-nav__cont-bottom">
          <div className="min-nav__cont-bottom-links">
            <div className="h-[50px] flex items-center justify-center group">
              <div className="min_link cursor-pointer" onClick={() => logout()}>
                <Logout />
              </div>
              <span className="group-hover:flex">Logout</span>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
