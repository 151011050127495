import { useEffect, useState } from "react";
import { useAuth } from "../../context/authContext";
import { useHeader } from "../../context/headerContext";
import { alertError, getCurrentWeek, NETWORK_STATE } from "../../utils";
import Loader from "../../assets/gifs/loader.gif";
import { ReactComponent as UpTrend } from "../../assets/svg/icons/trending_up.svg";
import { ReactComponent as DownTrend } from "../../assets/svg/icons/trending_down.svg";
import { getUsers } from "../../services/UserService";
import { getPreviousWeek } from "../../utils";
import { useNavigate } from "react-router-dom";

const InsightContainer = ({ children, hide }) => {
  const { minimizeNav } = useHeader();

  return (
    <div className={`insight-container ${minimizeNav ? 'h-[200px] 2xl:h-[220px]' : 'h-[180px] 2xl:h-[200px]'} ${hide ? 'opacity-[0]' : ''}`}>
      {children}
    </div>
  )
}

export const FailedNetwork =( {fetchUsers} ) => {
  return (
    <div className="h-full w-full flex flex-col items-center justify-center">
      <p className="text-[13px]">Failed to load</p>
      <p className="underline text-blue text-[13px] cursor-pointer" onClick={() => fetchUsers()}>Retry</p>
    </div>
  )
}

/* const getProperty = (property, value) => {
  return property + '-[' + value + ']';
} */

const UserLayout = ({ children }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [networkStatus, setNetworkStatus] = useState(NETWORK_STATE.IDLE);
  const [usersData, setUsersData] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState(usersData);
  const [currentUsers, setCurrentUsers] = useState(filteredUsers);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [dateFilter, setDateFilter] = useState(null);
  const { minimizeNav } = useHeader();
  const usersPerPage = 5;

  const [analytics, setAnalytics] = useState({
    lastWeekSignups: {
      count: null,
      users: [],
    },
    thisWeekSignups: {
      count: null,
      users: [],
    },
  });

  const getAnalytics = () => {
    const anotate = (num) => {
      if (num > 0) {
        return `+${num}`;
      } else if (num < 0) {
        return `-${num}`;
      } else {
        return `${num}`;
      }
    };

    // Get the total signups last week and this week
    const lastSunday = getPreviousWeek(1)?.getTime();
    const thisSunday = getCurrentWeek(0)?.getTime();
    const nextSunday = getCurrentWeek(7)?.getTime();

    const lastWeekSignups = usersData.filter((user) => {
      return user?.createdAt >= lastSunday || user?.createdAt < thisSunday;
    });
    const thisWeekSignups = usersData.filter((user) => {
      return user?.createdAt >= thisSunday || user?.createdAt < nextSunday;
    });
    setAnalytics({
      ...analytics,
      lastWeekSignups: { count: lastWeekSignups.length, users: lastWeekSignups },
      thisWeekSignups: { count: thisWeekSignups.length, users: thisWeekSignups },
      signupDiff: thisWeekSignups.length - lastWeekSignups.length,
    });
  }

  const fetchUsers = async () => {
    try {
      setNetworkStatus(NETWORK_STATE.LOADING);
      const response = await getUsers();
      const sortedUsers = response?.data?.data?.rows.sort((user1, user2) => {
        return user2.createdAt - user1.createdAt;
      });
      setUsersData(sortedUsers);
      setFilteredUsers(sortedUsers);
      setNetworkStatus(NETWORK_STATE.SUCCESS);
      getAnalytics();
    } catch (err) {
      console.log(err);
      if (err.response.status === 401) {
        alertError('Session Expired. Please Login');
        navigate('/login');
      }
      setNetworkStatus(NETWORK_STATE.ERROR);
    }
  }

  // Fetch users on page load
  useEffect(() => {
    auth.setHeaderText(['User']);

    fetchUsers();
  }, []);

  // Break users data into pages
  useEffect(() => {
    const start = (currentPage - 1) * usersPerPage;
    const end = (usersPerPage * (currentPage - 1)) + usersPerPage ;
    setCurrentUsers(filteredUsers.slice(start, end));
    setTotalPages(Math.ceil(filteredUsers.length / usersPerPage));
  }, [filteredUsers.length, currentPage]);

  // Filter users based on user name or user id
  const filterUsers = (e) => {
    setSearchText(e.target.value);
    if (e.target.value.length > 0) {
      const f = usersData.filter((user) => {
        const username = `${user.firstName} ${user.lastName}`;
        return username.toLowerCase().includes(e.target.value) || user.id.toString().includes(e.target.value);
      });
      setFilteredUsers(f)
    } else {
      setFilteredUsers(usersData);
    }
  }

  // Filter users by date created
  const filterByDate = () => {
    return;
  }

  // Filter users by services
  const filterByService = (filters) => {
    console.log(filters)
    return;
  }

  document.title = 'BLP Admin | User'

  return (
    <div className="user">
      <div className="user__hero">
        <InsightContainer>
          {
            networkStatus === NETWORK_STATE.LOADING ?
            <img src={Loader} alt="Loading..." width={20} className="mx-auto" />
            :
            (
              networkStatus === NETWORK_STATE.ERROR ?
              <FailedNetwork fetchUsers={fetchUsers} />
              :
              <div className="w-full h-full flex flex-col justify-center gap-3">
                <h5 className="header-text">Total signups</h5>
                {
                  analytics.signupDiff > 0
                  ?
                  <div>
                    <p className="info-text">
                      <span className={`bg-[#E3F8EE]`}>
                        <UpTrend className="inline" />
                        +{analytics.signupDiff}
                      </span>
                      More than last week
                    </p>
                  </div>
                  :
                  (
                    analytics.signupDiff === 0
                    ?
                    <div>
                      <p className="info-text">
                        <span className={`bg-[#F8F8F9]`}>
                          --0
                        </span>
                        Same as last week
                      </p>
                    </div>
                    :
                    <div>
                      <p className="info-text">
                        <span className={`bg-[#FEF0F0]`}>
                          <DownTrend className="inline" />
                          {analytics.signupDiff}
                        </span>
                        Less than last week
                      </p>
                    </div>
                  )
                }
                <div className="pr-1 flex items-center">
                  <div className="user-icons">
                    {
                      analytics.thisWeekSignups.users.slice(-5).map((user, index) => {
                        return (
                          <div key={index}>
                            {
                              user?.profilePic
                              ?
                              <div key={index} className={`${minimizeNav ? 'w-9' : 'w-7'} ${index !== 0 && 'ml-[-7px]'}`}>
                                <img src ={user.profilePic} alt='' />
                              </div>
                              :
                              (
                                user && 
                                <div 
                                  className={`
                                    bg-[#D7E6FF] rounded-full flex items-center
                                    justify-center ${minimizeNav ? 'w-9 h-9' : 'w-7 h-7'} ${index !== 0 && 'ml-[-7px]'}`
                                  }
                                >
                                  <p className={`text-[12px] text-[#132543] uppercase font-[800]`}>
                                    <span>{user?.firstName[0]}</span>
                                    <span>{user?.lastName[0]}</span>
                                  </p>
                                </div>
                              )
                            }
                          </div>
                        );
                        /* return (
                          <div key={index} className={`${minimizeNav ? 'w-9' : 'w-7'} ${index !== 0 && 'ml-[-7px]'}`}>
                            <img src ={user.profilePic} alt='' />
                          </div>
                        ) */
                      })
                    }
                  </div>
                  <p className="pl-3 text-[22px] font-[600]">{analytics?.thisWeekSignups.count}</p>
                </div>
              </div>
            )
          }
        </InsightContainer>
        <InsightContainer>
          {
            networkStatus === NETWORK_STATE.LOADING ?
            <img src={Loader} alt="Loading..." width={20} className="mx-auto" />
            :
            (
              networkStatus === NETWORK_STATE.ERROR ?
              <FailedNetwork fetchUsers={fetchUsers} />
              :
              <div className="w-full h-full flex flex-col justify-center gap-3">
                <h5 className="header-text">Cancelled signups</h5>
                <p className="info-text">
                  <span className={`bg-[#FEF0F0]`}>
                    <DownTrend className="inline pr-1 w-4" />
                    -2.0
                  </span>
                  Less than last week
                </p>
                <p className="text-[27px] font-[600]">3</p>
              </div>
            )
          }
        </InsightContainer>
        <InsightContainer>
          {
            networkStatus === NETWORK_STATE.LOADING ?
            <img src={Loader} alt="Loading..." width={20} className="mx-auto" />
            :
            (
              networkStatus === NETWORK_STATE.ERROR ?
              <FailedNetwork fetchUsers={fetchUsers} />
              :
              <div className="w-full h-full flex flex-col justify-center gap-3">
                <h5 className="self-center text-[27px] font-[600]">Version 1.0</h5>
              </div>
            )
          }
        </InsightContainer>
        <InsightContainer hide>
          {
            networkStatus === NETWORK_STATE.LOADING ?
            <img src={Loader} alt="Loading..." width={20} className="mx-auto" />
            :
            (
              networkStatus === NETWORK_STATE.ERROR ?
              <FailedNetwork fetchUsers={fetchUsers} />
              :
              <div className="opacity-[0]">
                <p className="absolute top-4 text-[14px]">Services Statistics</p>
                <div className="bar-chart">
                  <div className="bar-chart mb-[-30px]">
                    <div className="h-[calc(0%)] grid-line"></div>
                    <div className="h-[calc(25%)] grid-line"></div>
                    <div className="h-[calc(50%)] grid-line"></div>
                    <div className="h-[calc(75%)] grid-line"></div>
                  </div>
                  <div className="relative flex flex-col items-center h-[calc(65%)]">
                    <p className={`text-[10px] absolute bottom-[100%] z-[99]`}>100%</p>
                    <div className="absolute bottom-0 w-full bg-[#CAECEC] h-[100%] rounded-[6px]"></div>
                  </div>
                  <div className="relative flex flex-col items-center h-[calc(65%)]">
                    <p className="text-[10px] absolute bottom-[100%] z-[99]">100%</p>
                    <div className="absolute bottom-0 w-full bg-[#E3E2F4] h-[100%] rounded-[6px]"></div>
                  </div>
                  <div className="relative flex flex-col items-center h-[calc(65%)]">
                    <p className="text-[10px] absolute bottom-[100%] z-[99]">100%</p>
                    <div className="absolute bottom-0 w-full bg-[#FD9B3D] h-[100%] rounded-[6px]"></div>
                  </div>
                  <div className="relative flex flex-col items-center h-[calc(65%)]">
                    <p className="text-[10px] absolute bottom-[100%] z-[99]">100%</p>
                    <div className={`absolute bottom-0 w-full bg-[#8889D8] rounded-[6px] h-[100%]`}></div>
                  </div>
                </div>
              </div>
            )
          }
        </InsightContainer>
      </div>
      <div className="user__indicators"></div>
      <div className="w-full">
        {children}
      </div>
    </div>
  )
}

export default UserLayout;
