import { useState } from "react";
import { ReactComponent as DateIcon } from "../../assets/svg/icons/date_range.svg";
import { ReactComponent as ServiceIcon } from "../../assets/svg/icons/service-filter-icon.svg";
import { ReactComponent as ArrowDown } from "../../assets/svg/icons/arrow-down.svg";
import { ReactComponent as FilterIcon } from "../../assets/svg/icons/sort.svg";
import { ReactComponent as PageArrow } from "../../assets/svg/icons/page-arrow.svg";
import { ReactComponent as SearchIcon } from "../../assets/svg/icons/search.svg";
import UserRow from "../../components/user/UserRow";
import { NETWORK_STATE } from "../../utils";
import Loader from "../../assets/gifs/loader.gif";
import { FailedNetwork } from "./Users";
import UserInsight from "./UserInsignt";

const UsersList = ({
  users,
  totalPages,
  currentPage,
  setCurrentPage,
  filterByNameAndId,
  searchText,
  networkStatus,
  fetchUsers,
  allFilters,
  setAllFilters,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [displayedFilters, setDisplayedFilters] = useState(['Date']);
  const defaultDropdownState = { addFilters: false, date: false, services: false };
  const [dropdowns, setDropdowns] = useState(defaultDropdownState);
  const [currentDateFilter, setCurrentDateFilter] = useState(null);
  const [currentServiceFilter, setCurrentServiceFilter] = useState([]);

  const handleDateChange = (e) => {
    const filter1 = document.getElementById('date-filter-1');
    const filter2 = document.getElementById('date-filter-2');
    const filter3 = document.getElementById('date-filter-3');
    const filter4 = document.getElementById('date-filter-4');
    const filter5 = document.getElementById('date-filter-5');

    const filterMapping = { 1: filter1, 15: filter2, 30: filter3, 90: filter4, 365: filter5 };

    Object.keys(filterMapping).map((key) => {
      if (key !== e.target.value) {
        filterMapping[key].checked = false;
      }
    });
    if (currentDateFilter === e.target.value) {
      filterMapping[currentDateFilter].checked = false;
      setCurrentDateFilter(null);
      setAllFilters({ ...allFilters, date: null });
    } else {
      setCurrentDateFilter(e.target.value);
      setAllFilters({ ...allFilters, date: e.target.value });
    }
  }

  const handleServiceChange = (e) => {
    const filter1 = document.getElementById('service-filter-1');
    const filter2 = document.getElementById('service-filter-2');
    const filter3 = document.getElementById('service-filter-3');
    const filter4 = document.getElementById('service-filter-4');

    const filterMapping = { 1: filter1, 2: filter2, 3: filter3, 4: filter4 };
    if (currentServiceFilter.includes(e.target.value)) {
      filterMapping[e.target.value].checked = false;
      let newServiceFilter = [...currentServiceFilter];
      newServiceFilter.splice(newServiceFilter.indexOf(e.target.value), 1);
      setCurrentServiceFilter(newServiceFilter);
      setAllFilters({...allFilters, services: newServiceFilter});
    } else {
      setCurrentServiceFilter(currentServiceFilter.concat([e.target.value]));
      setAllFilters({...allFilters, services: allFilters?.services?.concat([e.target.value])});
    }
  }

  const handleSearch = (e) => {
    setAllFilters({...allFilters, searchString: e.target.value});
  }

  return (
    <div className="users-list">
      <h1 className="self-start">User Overview</h1>
      <div className="users-list__display">
        {
          networkStatus === NETWORK_STATE.LOADING
          ?
          <img src={Loader} alt="Loading..." width={20} className="mx-auto mt-20 self-center" />
          :
          (
            networkStatus === NETWORK_STATE.ERROR ?
            <FailedNetwork fetchUsers={fetchUsers} />
            :
            <div className="w-full">
            <div className="users-list__display-header">
              <div className="w-[230px] h-[40px] self-start border-[0.5px] border-[#EBEBEB] rounded-[8px] flex gap-3 items-center justify-between px-3">
                <SearchIcon />
                <input
                  type="text"
                  placeholder="Search User"
                  className="w-[200px] h-[37px] outline-none border-none font-[200] text-[14px]"
                  value={searchText}
                  onChange={handleSearch}
                />
              </div>
              <div className="flex items-center justify-between gap-3">
                {
                  displayedFilters.includes('Date') &&
                  <div className="relative">
                    <button
                      type="button"
                      className="date-btn"
                      onClick={() => setDropdowns({...defaultDropdownState, date: !dropdowns.date})}
                    >
                      <DateIcon className="scale-[0.9]" />
                      Date
                      <ArrowDown fill="black" className={`scale-[1.4] ${dropdowns.date ? 'rotate-[-90deg]' : ''}`} />
                    </button>
                    {
                      <div
                        tabIndex={0}
                        /* onBlur={() => setDropdowns(defaultDropdownState)} */
                        className={`dropdown-container ${dropdowns.date ? 'flex flex-col' : 'hidden'}`}
                      >
                        <div className="dropdown-option rule">
                          <input id="date-filter-1" type={'radio'} value={1} onClick={handleDateChange} />
                          Last Day
                        </div>
                        <div className="dropdown-option rule">
                          <input id="date-filter-2" type={'radio'} value={15} onClick={handleDateChange} />
                          Last 15 Days
                        </div>
                        <div className="dropdown-option rule">
                          <input id="date-filter-3" type={'radio'} value={30} onClick={handleDateChange} />
                          Last 30 Days
                        </div>
                        <div className="dropdown-option rule">
                        <input id="date-filter-4" type={'radio'} value={90} onClick={handleDateChange} />
                          Last Quarter
                        </div>
                        <div className="dropdown-option">
                          <input id="date-filter-5" type={'radio'} value={365} onClick={handleDateChange} />
                          Last Year
                        </div>
                      </div>
                    }
                  </div>
                }
                {
                  displayedFilters.includes('Services') &&
                  <div className="relative">
                    <button
                      type="button"
                      className="service-btn"
                      onClick={() => setDropdowns({...defaultDropdownState, services: !dropdowns.services})}
                    >
                      <ServiceIcon className="scale-[0.9]" />
                      Services
                      <ArrowDown fill="black" className={`scale-[1.4] ${dropdowns.services ? 'rotate-[-90deg]' : ''}`} />
                    </button>
                    {
                      <div
                        tabIndex={0}
                        className={`dropdown-container ${dropdowns.services ? 'flex flex-col' : 'hidden'}`}
                      >
                        <div className="dropdown-option rule">
                          <input id="service-filter-1" type={'radio'} value={1} onClick={handleServiceChange} />
                          Api Services
                        </div>
                        <div className="dropdown-option rule">
                          <input id="service-filter-2" type={'radio'} value={2} onClick={handleServiceChange} />
                          Validator Nodes
                        </div>
                        <div className="dropdown-option rule">
                          <input id="service-filter-3" type={'radio'} value={3} onClick={handleServiceChange} />
                          Dedicated Nodes
                        </div>
                        <div className="dropdown-option">
                          <input id="service-filter-4" type={'radio'} value={4} onClick={handleServiceChange} />
                          Data Services
                        </div>
                      </div>
                    }
                  </div>
                }
                <div className="relative">
                  <button
                    type="button"
                    onClick={() => setDropdowns({...defaultDropdownState, addFilters: !dropdowns.addFilters})}
                    className="filter-btn"
                  >
                    <FilterIcon className={`scale-[0.9]`} />
                    Add Filters
                  </button>
                  {
                      dropdowns.addFilters &&
                      <div
                        className="dropdown-container"
                      >
                        {
                        /*  !displayedFilters.includes('Date') &&  */
                          <div
                            onClick={() => {
                              if (!displayedFilters.includes('Date')) {
                                const newFilters = displayedFilters.push('Date');
                                setDisplayedFilters(newFilters);
                              }
                              setDropdowns({ ...dropdowns, addFilters: false });
                            }}
                            className="dropdown-option rule"
                          >
                            <DateIcon className="scale-[0.9]" />
                            <p>Date</p>
                          </div>
                        }
                        {
                          /* !displayedFilters.includes('Services') &&  */
                          <div
                            onClick={() => {
                              if (!displayedFilters.includes('Services')) {
                                setDisplayedFilters(displayedFilters.concat(['Services']));
                              }
                              setDropdowns({ ...dropdowns, addFilters: false });
                            }}
                            className="dropdown-option"
                          >
                            <ServiceIcon className="scale-[0.9]" />
                            <p>Services</p>
                          </div>
                        }
                      </div>
                    }
                </div>
              </div>
            </div>

            <div>
              <UserRow header />
              {
                users.map((user, index) => {
                  return (
                    <div key={index}>
                      <UserRow
                        user={user}
                        setSelectedOption={setSelectedOption}
                        selectedOption={selectedOption}
                      />
                    </div>
                  )
                })
              }
            </div>
            </div>
          )
        }
        <div className="users-list__display-footer">
          <button
            type="button"
            onClick={() => {
              currentPage > 1 && setCurrentPage(currentPage - 1);
            }}
            className="prev-btn">
            <PageArrow className="rotate-[180deg]" />
          </button>
          <button
            type="button"
            onClick={() => {
              currentPage < totalPages && setCurrentPage(currentPage + 1);
            }}
            className="next-btn">
            Next Page
            <PageArrow />
          </button>
          <div className="absolute right-7 text-[12px]">Page {currentPage} of {totalPages ? totalPages : 1}</div>
        </div>
      </div>
    </div>
  )
}

export default UsersList;
