import { useNetworks } from "../../context/networkContext";
import { ReactComponent as Composable } from "../../assets/svg/networks/Composable.svg";
import { ReactComponent as Flow } from "../../assets/svg/networks/Flow.svg";
import { ReactComponent as Kusama } from "../../assets/svg/networks/Kusama.svg";
import { ReactComponent as Moonriver } from "../../assets/svg/networks/Moonriver.svg";
import { ReactComponent as Polkadot } from "../../assets/svg/networks/Polkadot.svg";
import { ReactComponent as OptionsIcon } from "../../assets/svg/icons/options.svg";
import { ReactComponent as ProjectIcon } from "../../assets/svg/icons/projecticon.svg";
import Moonbeam from "../../assets/img/Moonbeam.png";
import { useEffect, useState } from "react";
import { getProjects } from "../../services/ProjectsService";
import Loader from "../../assets/gifs/loader.gif";
import { useNavigate } from "react-router-dom";
import { getDateTime } from "../../utils";
import { useAuth } from "../../context/authContext";

export const networkIcons = {
  Polkadot: <Polkadot />,
  Kusama: <Kusama />,
  Composable: <Composable />,
  Moonbeam: <img src={Moonbeam} alt="Moonbeam" width={70} />,
  Moonriver: <Moonriver />,
  Flow: <Flow />,
  Rococo: "",
  Subspace: "",
}

const ProjectBox = ({ project, header, networks }) => {
  const projectNetwork = networks?.filter((network) => {
    return network.id === project?.networkId;
  });
  return (
    <div className='w-full'>
      {
        header
        ?
        <div className="box__header">
          <div className="absolute left-0 w-2 h-2 bg-[#121212] rounded-full"></div>
          <p className="w-[58%] md:w-[30%]">Project Name</p>
          <p className="w-[38%] md:w-[30%]">Network</p>
          <p className="hidden md:flex w-[25%]">Status</p>
          <p className="hidden md:flex w-[15%]">Date Created</p>
        </div>
        :
        (projectNetwork?.length > 0 && <div className="box">
          <div className="w-[58%] md:w-[30%] flex items-center gap-2">
            <ProjectIcon />
            <p className="truncate pr-5">{project?.projectName}</p>
          </div>
          <div className="w-[58%] md:w-[30%] flex items-center">
            <div className="scale-[0.6] -ml-3">{networkIcons[projectNetwork[0]?.name]}</div>
            {projectNetwork[0]?.name}
          </div>
          <div className="hidden md:flex w-[25%]">
            <div className="w-[70px] h-7 flex items-center justify-between bg-[#ECFBEF] text-[#2C9067] border-[0.5px] border-[#2C9067] rounded-[80px] p-2 font-[400] text-[13px]">
              <div className="w-2 h-2 bg-[#2C9067] rounded-full"></div>
              <p>Active</p>
            </div>
          </div>
          <div className="hidden md:flex w-[15%]">
            <p className="font-[400]">
              {getDateTime(project?.createdAt).date}
              <span className="text-[11px] text-[#797979]">{getDateTime(project?.createdAt).time}</span>
            </p>
          </div>
        </div>)
      }
    </div>
  )
}

const ApiServices = () => {
  const { networks } = useNetworks();
  const [projects, setProjects] = useState(null);
  const [displayedProjects, setDisplayedProjects] = useState(null);
  const [displayAll, setDisplayAll] = useState(false);
  const [connectedNetworks, setConnectedNetworks] = useState(new Set());
  const navigate = useNavigate();
  const auth = useAuth();

  const fetchProjects = async () => {
    try {
      const response = await getProjects();
      setProjects(response?.data?.data?.allServices?.rows);
    } catch (err) {
      console.log(err);
      err?.response?.status === 401 && navigate('/login');
    }
  }

  useEffect(() => {
    if (displayAll || projects?.length <= 3) {
      setDisplayedProjects(projects);
    } else {
      const projectsCopy = projects ? [...projects] : [];
      projectsCopy?.length === 0 && setDisplayedProjects([]);
      projectsCopy?.length > 3 && setDisplayedProjects(projectsCopy?.splice(0, 3));
    }
  }, [displayAll, projects]);

  // Fetch project on page load
  useEffect(() => {
    auth.setHeaderText(['API Services']);
    fetchProjects();
  }, []);

  useEffect(() => {
    if (projects && networks) {
      projects.map((project) => {
        networks.filter((network) => {
          if ((project.networkId === network.id)) {
            connectedNetworks.add(network.name);
          }
        });
        setConnectedNetworks(new Set(connectedNetworks.values()));
      })
    }
  }, [projects, networks]);

  return (
    <div className="api-services">
      <div className="api-services__available">
        <h1 className="title">Available Networks</h1>
        <div className="networks">
          {
            networks?.map((network, index) => {
              return (
                <div key={index} className="box">
                  <div className="scale-[0.7] 2xl:scale-[0.8]">{networkIcons[network?.name]}</div>
                  <h2>{network?.name}</h2>
                </div>
              )
            })
          }
        </div>
      </div>
      <div className="api-services__connected">
        <h1 className="title">Connected Networks</h1>
        <div className="box">
          <div className="flex items-center">
            {
              Array.from(connectedNetworks?.values())?.map((networkName, index) => {
                return (
                  <div key={index} className={`scale-[0.7] 2xl:scale-[0.8] rounded-full border-[0.5px] border-white ${index !== 0 ? '-ml-8': ''}`}>
                    {networkIcons[networkName]}
                  </div>
                );
              })
            }
          </div>
          <div className="avatars">{connectedNetworks && connectedNetworks.size}</div>
          <button className="options">
            <OptionsIcon />
          </button>
        </div>
      </div>
      <div className="api-services__active">
        <h1 className="title">Active Projects({projects?.length})</h1>
        <div className="w-full">
          {
            !displayedProjects
            ?
            <div className="relative h-[200px] w-full">
              <img src={Loader} alt="loading projects..." className="absolute left-[50%] top-[50%]" />
            </div>
            :
            <div className="container">
              <ProjectBox header />
              {
                displayedProjects?.map((project, index) => {
                  return (
                    <div key={index} className="w-full">
                      <ProjectBox project={project} networks={networks} />
                    </div>
                  )
                })
              }
            </div>
          }
        </div>
        {
          projects?.length > 3 &&
          <button onClick={() => setDisplayAll(!displayAll)} className="underline text-blue text-[14px] self-end">
            {displayAll ? 'Collapse' : 'See all'}
          </button>
        }
      </div>
    </div>
  )
}

export default ApiServices;
